import Head from "next/head"
import { secrets } from "secrets"

interface CommonHeadProps {
  title?: string
  description?: string
  image?: string
  url?: string
  hide?: boolean
}

const CommonHead = ({
  title = "getpacked",
  description = "Lokale Produkte - einfach bestellen.", //`Wir bieten unser gesamtes Sortiment hier online an. Schau dich um, lass dich inspirieren und bestell dann ganz bequem auf deinem Smartphone oder Computer`
  image = "/android-icon-256x256.png",
  url,
  hide,
}: CommonHeadProps) => {
  return (
    <Head>
      <meta key="charSet" charSet="utf-8" />

      <title>{title}</title>
      <meta key="og:title" property="og:title" content={title} />

      <meta key="description" name="description" content={description} />
      <meta key="og:description" property="og:description" content={description} />

      <meta key="og:image" property="og:image" content={image} />
      <meta key="twitter:card" property="twitter:card" content={image} />

      {url ? <meta key="og:url" property="og:url" content={url} /> : null}

      <meta key="msapplication-TileColor" name="msapplication-TileColor" content="#da532c" />
      <meta key="msapplication-TileImage" name="msapplication-TileImage" content="/mstile-150x150.png" />
      <meta key="theme-color" name="theme-color" content="#ffffff" />

      <link key="icon1" rel="icon" type="image/ico" href="/favicon.ico" />
      <link key="icon2" rel="shortcut icon" href="/favicon.ico" />
      <link key="icon3" rel="apple-touch-icon" href="/apple-touch-icon.png" />
      <link key="icon4" rel="icon" type="image/png" sizes="256x256" href="/android-icon-256x256.png" />
      <link key="icon5" rel="icon" type="image/png" sizes="192x192" href="/android-icon-192x192.png" />

      <script
        key="cookiebot"
        id="Cookiebot"
        src="https://consent.cookiebot.com/uc.js"
        data-cbid={secrets.cookiebot.id}
        type="text/javascript"
        async
      ></script>

      {/* Google tag (gtag.js) */}
      <script async src="https://www.googletagmanager.com/gtag/js?id=AW-493982319"></script>
      <script
        dangerouslySetInnerHTML={{
          __html: `window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', 'AW-493982319');`,
        }}
      ></script>

      {hide ? <meta key="robots" name="robots" content="noindex,follow" /> : null}
      <meta
        key="google-site-verification"
        name="google-site-verification"
        content="Q0J2veKUxUNVbQdMcsqCan4kystgFCWTCh4FgGdY_JA"
      />
    </Head>
  )
}

export default CommonHead
